'use strict';

/**
 * Review Massonry Constructor
 *
 * @param data {parent:JqueryElement, name:string, limit:int, reviewIds:array}
 * @constructor
 */
let ReviewMassonryConstructor = function (data) {
    const name = data.name;
    let reviewIds = data.reviewIds;
    const limit = 4;
    const parent = data.parent;
    const element = $(parent).find('div[class="row masonry-grid masonry-grid--size1"]');
    const btn = $(parent).find('.add-reviews');
    const locationName =$(parent).data('location');

    /**
     * @returns {string}
     */
    const generatedGetParams = function () {
        let getParams = '';
        if (reviewIds.length !== 0) {
            let arrayReviewsIds;
            if (reviewIds.length > limit) {
                arrayReviewsIds = reviewIds.splice(0, limit);
            } else {
                arrayReviewsIds = reviewIds;
                reviewIds = [];
                $(btn).remove();
            }
            $(arrayReviewsIds).each(function (index, element) {
                getParams += '&reviewsId[]=' + element;
            });
        }

        return getParams;
    };

    const addReview = function () {
        $.ajax({
            type: 'GET',
            url: '/api/reviews-ids?' + generatedGetParams(),
            dataType: 'json',
            success: function success(response) {
                $(response).each(function (index, item) {
                    $(element).append(createElementReview(item));
                });

                if ((new URL(document.location.href)).searchParams.get('reviewAnchor') !== null) {
                    anchor('reviewAnchor');
                }
            },
            error: function error(response) {
                console.error(response.responseText); // needed
            }
        });

    };

    this.init = function () {
        if (reviewIds.length > 0) {
            $(parent).parent('.js-review-section').css('display', 'block');
            $(btn).on('click', function () {
                addReview();
                if (reviewIds === []) {
                    $(btn).remove();
                }

                setTimeout(function () {
                    if (typeof initParallax === 'function') {
                        initParallax();
                    }
                }, 500);
            });
            addReview();
        }
    };

    /**
     * @param data
     * @returns {jQuery}
     */
    var createElementReview = function (data) {

        var stars = '';
        if (typeof data.rating !== 'undefined') {
            stars = $(`<ul class="list-stars" data-name="stars"></ul>`);
            for (var index = 0; index < data.rating; index++) {
                stars.append(`<li><i class="fa fa-star basic-yellow"></i></li>`);
            }
        }
        var testimonialHead = ``;
        var hostedBy = ``;
        var reviewTitle = `<p class="review-title">${data.title}</p>`;
        if (name === 'location') {
            if ((typeof data.linkToSample !== 'undefined') || (typeof data.hostLink !== 'undefined')) {
                if (data.hostLink.length > 0) {
                    if (data.isHostHidden) {
                        testimonialHead = `<div class="testimonial__head">
                                          <a data-name="title" data-islocation="true">
                                             <span>${locationName.toUpperCase()}, ${data.itinerary.subtitle.toUpperCase()}</span>
                                          </a>
                                      </div>`;
                    } else {
                        testimonialHead = `<div class="testimonial__head">
                                          <a href="${data.hostLink + '?review=' + data.id + '&reviewAnchor=true'}" data-name="title" data-islocation="true">
                                             <span>${locationName.toUpperCase()}, ${data.itinerary.subtitle.toUpperCase()}</span>
                                          </a>
                                      </div>`;
                    }
                }
                if ((typeof data.linkToSample !== 'undefined') && (data.linkToSample.length > 0)) {
                    testimonialHead = `<div class="testimonial__head">
                                          <a href="${data.linkToSample + '?review=' + data.id + '&reviewAnchor=true'}" data-name="title" data-islocation="true">
                                             <span>${data.itinerary.subtitle.toUpperCase()}</span>
                                          </a>
                                      </div>`;
                }
            }
        } else if ((typeof data.linkToSample !== 'undefined') && (data.linkToSample.length > 0)){
            reviewTitle = `<p class="review-title"><a  href="${data.linkToSample + '?review=' + data.id + '&reviewAnchor=true'}" target="_blank" >${data.title}</a></p>`;
        }

        if ((typeof data.hostLink !== 'undefined') && (data.hostLink.length > 0)) {
            if (data.isHostHidden) {
                hostedBy = `, <span>hosted by ${data.itinerary.title}</span>`;
            } else {
                hostedBy = `, <span>hosted by <a href="${data.hostLink}" target="_blank" rel="noopener">${data.itinerary.title}</a></span>`;
            }
        }

        var element = $(`<div class="col-md-6 col-lg-4">
                            <div class="testimonial review-${name} reviews-host">
                                     ` + testimonialHead + ` 
                                <div class="testimonial__content">
                                    ` +  reviewTitle + `
                                    <p class="review-review">${data.review}</p>
                                    <p class="review-created">${data.created_at_formatted}</p>
                                    <p class="review-author">Reviewed by ${data.first_name} ${data.last_name_short}` + hostedBy +`</p>
                                </div>
                            </div>
                       </div>`);

        element.find('.testimonial__content').prepend(stars);

        return element;
    };
};

/**
 * Review Slick Slider Constructor
 *
 * @param data {parent:JqueryElement, name:string, limit:int, reviewIds:array}
 * @constructor
 */
let ReviewSlickSliderConstructor = function (data) {
    var maxLines = 10;
    var parent = data.parent;
    var name = data.name;
    var reviewIds = data.reviewIds;
    var limit = 6;
    var element = $(parent).find('section[class="reviews-list reviews-list-slider slick-slider js-review"]');
    var readMore = $(parent).data('read-more');

    const defaultParamSlickSlider = {
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                    dots: false,
                    centerPadding: '40px',
                },
            },
        ],
    };

    const customParamSlickSlider = {
        slidesToShow: 4,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 992,
				settings: {
					centerMode: true,
					slidesToShow: 2,
					centerPadding: '60px',
				},
			},
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
					arrows: false,
					slidesToShow: 1,
					centerPadding: '30px',
				},
			},
		],
    };

    /**
     * @returns {string}
     */
    var generatedGetParams = function () {
        var getParams = '';
        if (reviewIds.length !== 0) {
            let arrayReviewsIds;
            if (reviewIds.length > limit) {
                arrayReviewsIds = reviewIds.splice(0, limit);
            } else {
                arrayReviewsIds = reviewIds;
                reviewIds = [];
            }
            $(arrayReviewsIds).each(function (index, element) {
                getParams += '&reviewsId[]=' + element;
            });
        }

        return getParams;
    };

    var addReview = function () {
        var getParams = generatedGetParams();
        if (getParams.length > 0) {
            $.ajax({
                type: 'GET',
                url: '/api/reviews-ids?' + getParams,
                dataType: 'json',
                success: function success(response) {
                    $(element).off('beforeChange', addReview);
                    $(response).each(function (index, item) {
                        let slickElement = createElementReview(item);
                        $(element).slick('slickAdd', slickElement);
                        addReviewText(item, $('.slick-slide').not('.slick-cloned').find('.js-help-block'));
                    });
                    $(element).on('beforeChange', addReview);

                    if ((new URL(document.location.href)).searchParams.get('reviewAnchor') !== null) {
                        anchor('reviewAnchor');
                    }
                },
                error: function error(response) {
                    console.error(response.responseText); // needed
                }
            });
        } else {
            $(element).off('beforeChange', addReview);
        }
    };

    this.init = function () {    
        if (reviewIds.length > 0) {
            $(parent).css('display', 'block');
            $(element).slick(
                parent.data('custom-param-slick') ? customParamSlickSlider : defaultParamSlickSlider
            );
            addReview();
            limit = 2;
        }
    };

    /**
     * @param data
     * @returns {jQuery}
     */
    var createElementReview = function (data) {

        var stars = '';
        if (typeof data.rating !== 'undefined') {
            stars = $(`<ul class="review-star-list" data-name="stars"></ul>`);
            for (var index = 0; index < data.rating; index++) {
                stars.append(`<li><i class="fa fa-star" aria-hidden="true"></i></li>`);
            }
        }

        var reviewTitle = `<a class="title-link" data-islocation="true">
                                   <h3 class="review-title">${data.title}</h3>
                              </a>`;
        var hostedBy = ``;
        if ((typeof data.linkToSample !== 'undefined') || (typeof data.hostLink !== 'undefined')) {
            if ((typeof data.linkToSample !== 'undefined') && (data.linkToSample.length > 0)) {
                reviewTitle = `<a href="${data.linkToSample}" class="title-link" data-islocation="true">
                                      <h3 class="review-title">${data.title}</h3>
                                  </a>`;
            }
            if ((typeof data.hostLink !== 'undefined') && (data.hostLink.length > 0)) {
                if (data.isHostHidden) {
                    hostedBy = `, <span>hosted by ${data.itinerary.title}</span>`;
                } else {
                    hostedBy = `, <span>hosted by <a href="${data.hostLink}" target="_blank" rel="noopener">${data.itinerary.title}</a></span>`;
                }
            }
        }

        var element = $(`<div><div>
                            <div class="testimonial review-${name}">
                                <div class="testimonial__content review review-host city-review review-${name}" data-content="review">
                                    ` + reviewTitle + `
                                    <p class="js-help-block" style="visibility: hidden">Help</p>
                                    <p class="review-text">${data.review}</p>
                                    <p class="review-date">${data.created_at_formatted}</p>
                                    <p class="review-author">Reviewed by ${data.first_name} ${data.last_name_short}` + hostedBy + `</p>
                                </div>
                            </div>
                       </div></div>`);

        element.find('div[data-content="review"]').prepend(stars);

        return element;
    };

    /**
     *
     * @param data {object}
     * @param jsHelpBlock {JqueryObject}
     */
    var addReviewText = function (data, jsHelpBlock) {
        let parent = jsHelpBlock.parent();
        let reviewTitle = $(parent).find('a[class="title-link"]');
        let heightOfReviewText = ($(jsHelpBlock).height() * maxLines) - reviewTitle.find('.review-title').height();
        let reviewTextBlock = parent.find('.review-text');
        let htmlLink = ``;
        if ((typeof data.linkToSample !== 'undefined' && data.linkToSample.length > 0) || (typeof data.hostLink !== 'undefined' && data.hostLink.length > 0 && data.isHostHidden)) {
            let link = '';
            link = ((typeof data.linkToSample !== 'undefined') && (data.linkToSample.length > 0)) ? (data.linkToSample + '?review=' + data.id + '&reviewAnchor=true') : (data.hostLink + '?review=' + data.id + '&reviewAnchor=true');
            htmlLink = `<a href="${link}" style="margin: 5px 0 5px 0">Read more</a>`;
            reviewTitle.attr('href', link);
        } else {
            reviewTitle.html(`<h3 class="review-title">${data.title}</h3>`);
            htmlLink = `<p style="margin: 5px 0 5px 0">Read more</p>`;
        }
        if (heightOfReviewText < reviewTextBlock.height()) {
            reviewTextBlock.text('');
            let arrayWords = data.review.split(' ');
            let index = 0;
            for (index; index < arrayWords.length; index++) {
                reviewTextBlock.text(reviewTextBlock.text() + arrayWords[index] + ' ');
                if (heightOfReviewText < reviewTextBlock.height()) {
                    reviewTextBlock.text(reviewTextBlock.text().slice(0, (reviewTextBlock.text().length - arrayWords[index].length) - 2) + '...');
                    break;
                }
            }
            if ((readMore !== 'undefined') && (readMore === 'show_more')) {
                const link = $(`<a class="Read-more-btn" style="margin: 5px 0 5px 0" data-index-word="${index}" data-array-words="${arrayWords}" data-action="show">Read more</a>`);
                reviewTextBlock.after(link);
            } else {
                reviewTextBlock.after(htmlLink);
            }
        }
        $(jsHelpBlock).remove();
    };
};

let AddReviewWidgets = function () {
    this.selector = '[data-initiator="reviews"]';
    let elementType = {
        'masonry': ReviewMassonryConstructor,
        'slick': ReviewSlickSliderConstructor
    };

    this.init = function () {
        if (((new URL(document.location.href)).searchParams.get('review') !== null)) {
            addWidget();
        } else {
            $(window).one('scroll', function() {
                addWidget();
            });
        }
    };

    const addWidget = function () {
        const reviewIdParam = ((new URL(document.location.href)).searchParams.get('review') !== null) ? '&review=' + (new URL(document.location.href)).searchParams.get('review') : '';

        if (typeof (reviewGetParams) !== 'undefined') {
            $.ajax({
                type: 'GET',
                url: '/api/reviews-by-params?' + reviewGetParams + reviewIdParam,
                dataType: 'json',
                success: function success(response) {
                    $(Object.keys(response)).each(function (index, item) {
                        let parent =  $('div[data-name="' + item + '"]');
                        let type = parent.data('type');

                        if (parent.data('mobile')) {
                            if (!isScreenSizeMobile()) {
                                parent = $('div[data-name="' + item + '"][data-mobile="true"]');
                                type = parent.data('type');
                            } else {
                                parent = $('div[data-name="' + item + '"][data-mobile="false"]');
                                type = parent.data('type');
                            }
                        }
                        if (elementType.hasOwnProperty(type)) {
                            /**
                             * this dynamic methods need 4 params
                             *
                             * @param element(Jquery)
                             * @param item(name of review type[experience, location, host])
                             * @param array
                             */
                            let reviewObject = new elementType[type]({
                                parent: parent,
                                name: item,
                                reviewIds: response[item],
                            });

                            reviewObject.init();
                        }
                    });
                    if ($('[data-read-more="true"]')) {
                        $(document).on('click', '.Read-more-btn', function (event) {
                            let heightBefore = $(event.target).parent().find('.review-text').height();
                            if ($(event.target).attr('data-action') === 'show') {
                                $(event.target).attr('data-action', 'hide').text('Hide');
                                $(event.target).parent().find('.review-text').text((($(event.target).data('array-words')).split(',')).join(' '));
                            } else if ($(event.target).attr('data-action') === 'hide') {
                                $(event.target).attr('data-action', 'show').text('Read more');
                                $(event.target).parent().find('.review-text').text((($(event.target).data('array-words')).split(',')).slice(0, $(event.target).data('index-word') - 1).join(' ') + '...');
                            }
                            let deltaHeight = $(event.target).parent().find('.review-text').height() - heightBefore;
                            $(event.target).parents('.testimonial__content').height($(event.target).parents('.testimonial__content').height() + deltaHeight);
                        });
                    }
                },
                error: function error(response) {
                    console.error(response.responseText); // needed
                }
            });
        }
    };
};

new SelectorInitiator().setObject(new AddReviewWidgets());

function anchor(idAnchor) {
    let fixHeaderHeight = 0;

    $('.header').children().each(function(index, item){
        fixHeaderHeight += $(item).height();
    });

    $([document.documentElement, document.body]).animate({
            easing: 'easing',
            scrollTop: ($('#' + idAnchor).offset().top - (fixHeaderHeight))
        }, 1000, function() {
        if (($('#' + idAnchor).offset().top - 200) > $(window).scrollTop()){
            $([document.documentElement, document.body]).animate({
                easing: 'easing',
                scrollTop: ($('#' + idAnchor).offset().top - (fixHeaderHeight))
            }, 1);
        }
    });
}
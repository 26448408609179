'use strict';

let LoadExperiencesForExtendedWidget = function (imageHeightVariable) {

    this.selector = '.see-more';
    this.targetItems = '#experiences';
    this.btn = $(this.selector);
    const translateAlias = 'experiences';
    const loadConfig = {
        mobile_offset: 4,
        desktop_offset: 12
    };
    let experiencesWidget;
    let exampleWidget;
    let experiencesCounter;
    let totalExperiences;
    let translationService;
    let uploadExparienceOserver;
    const videoChatCategoryId = 16;

    this.init = function () {
        experiencesWidget = $(this.targetItems);
        exampleWidget = $(experiencesWidget.children()[0]).clone(true);
        experiencesCounter = totalExperiences = experiencesWidget.children().length;
        //translationService = new SelectorInitiator().getObjectBySelector('.translatable'); //temporary disabled until translation Off
        uploadExparienceOserver = new ObserverAdapter();
        this.setDefaultUrl();
        this.btn.on('click', function(e) {
            e.preventDefault();
            addExperiencesForExtendedWidget($(this));
        });

        if (!window.location.search.length) {
            isMobile ? this.btn.data('limit', loadConfig.mobile_offset - totalExperiences) : this.btn.data('limit', loadConfig.desktop_offset - totalExperiences);
            $('.see-more').trigger('click');
        } else {
            isMobile ? this.btn.data('limit', loadConfig.mobile_offset) : this.btn.data('limit', loadConfig.desktop_offset);
        }

        if (!isMobile) {
            $('.js-single-experience').find('.link-to-experience').attr('target', '_blank');
        }
    };

    this.setDefaultUrl = function () {
        this.btn.attr('href', getDefaultUrl(this.btn));
    };

    this.reloadExperiences = function () {
        this.btn.data('offset', 0);
        experiencesWidget.empty();
        totalExperiences = experiencesCounter = 0;
        this.btn.trigger('click');
        //translationService.unSubscribe(translateAlias); //temporary disabled until translation Off
    };

    let getDefaultUrl = function ($btn) {
        return $btn.data('defaulturl');
    };

    let addExperiencesForExtendedWidget = function($btn) {
        $btn.removeClass('invisible');
        applyLoaderToBtn($btn);

        $.ajax({
            url: $btn.attr('href') + 'offset=' + $btn.data('offset') + '&limit=' + $btn.data('limit'),
            type: 'GET',
            success: function (response) {
                removeLoaderFromBtn($btn);
                uploadExparienceOserver.run(response);
                isMobile ? $btn.data('limit', loadConfig.mobile_offset) : $btn.data('limit', loadConfig.desktop_offset);
                if ($('.js-count-experiences')) {
                    $('.js-count-experiences').text(response.total);
                }
                $btn.data('total', response.total);
                response = response.data;

                if (response && response.length > 0) {
                    buildItems(response);
                    imageHeightVariable.init();
                    $btn.data('offset', parseInt($btn.data('offset'), 10) + response.length);
                }

                if (parseInt($btn.data('total'), 10) <= totalExperiences) {
                    $btn.addClass('invisible');
                    //Temporary hide
                    // !$('.special-card').length ? addItemToTarget(specialCard) : false;
                }

                //translationService.initNewSubscribed(translateAlias); //temporary disabled until translation Off

                setTimeout(function () {
                    $('.card-block-item.appearance-effect').removeClass('appearance-effect');

                    if (typeof initParallax === 'function') {
                        initParallax();
                    }
                }, 500);
            },

            error: function (response) {
                removeLoaderFromBtn($btn);
            }
        });
    };

    let addItemToTarget = function (item) {
        if (experiencesWidget.hasClass('slick-initialized')) {
            experiencesWidget.slick('slickAdd',item);
        } else {
            experiencesWidget.append(item);
        }
        //translationService.subscribeItem(item, translateAlias); //temporary disabled until translation Off
    };

    let buildItems = function (response) {
        buildExperiencesExtendedWidgets(exampleWidget, response).forEach((item) => {
            //Temporary hide
            // if (totalExperiences === 7) {
            //     addItemToTarget(specialCard);
            // }
            addItemToTarget(item);
            experiencesCounter++;
            totalExperiences++;
        });
    };

    let buildExperiencesExtendedWidgets = function(exampleWidget, expriencesData) {

        let newWidgets = [];

        $.each(expriencesData, function(index, value) {
            let wd = exampleWidget.clone(true);

            wd.find('[href]').attr('href', value.link);

            if (!isMobile) {
                wd.find('[href]').attr('target', '_blank');
            }

            let imageBlock = wd.find('.js-image-block');
            let virtualChatImageBlock = wd.find('.virtual-chat-img-block');
            if (value.isVirtual && !value.image.length) {
                imageBlock.attr('hidden', 'hidden');
                virtualChatImageBlock.removeAttr('hidden');
            } else {
                virtualChatImageBlock.attr('hidden', 'hidden');
                imageBlock.removeAttr('hidden');
                imageBlock.removeAttr('data-src');
                imageBlock.attr('src', value.image);
                imageBlock.attr('alt', value.image_alt);
            }

            wd.find('[data-name=duration]').html(value.duration);
            wd.find('[data-name=price]').html(value.price);
            wd.find('[data-name=duration_text]').html(value.duration_text);
            wd.find('[data-name=price_text]').html(value.price_text);
            wd.find('[data-name=country-city]').html(value.city + ', ' + value.country);
            wd.find('a.link-to-review').attr('href', value.link + '?reviewAnchor=true');

            var category = wd.find('[data-name=category]');
            if (value.category.name) {
                category.html(value.category.name);
                if (category.hasClass('color-variable')) {
                    category.css({'background-color': value.category.color});

                    if (value.category.color === '#FAB64E') {
                        category.css({'color': 'var(--blue-basic)'});
                    }

                }
            } else {
                category.html('').css({'padding': 0});

            }

            wd.find('.article__image-container').find('.article__label').remove();
            if (value.new_label) {
                wd.find('.article__image-container').append($('<span class="article__label translatable">New</span>'));
            }

            var titleLink = wd.find('[data-name=title]').find('.main-title');
            titleLink.html(value.title);

            let starsElements  = wd.find('[data-name=stars]');
            starsElements.children().remove();
            if (parseInt(value.no_reviews, 10) >= parseInt(starsElements.data('min'), 10)) {
                for (let i = 0; i < value.rating; i++) {
                    starsElements.append($('<li><i class="star-icon" aria-hidden="true"></i></li>'));
                }
            }
            // for home page widget
            wd.find('[data-name=additional_description]').html(value.additional_description);
            wd.find('[data-name=description]').html(value.description);
            wd.find('[data-name=price]').html(value.price);

            newWidgets.push(wd);
        });

        return newWidgets;
    };

    this.loadExperienceObserver = function () {
        return uploadExparienceOserver;
    };
};

let ImageHeightVariable = function() {

    this.selector = '.image-height-variable';
    this.imageHeights = [303, 421, 351]; // size for cards

    this.init = function () {

        let imageContainer = $(this.selector);
        let heights = this.imageHeights;
        let sizeIndex = Math.ceil(imageContainer.find('.img').length / heights.length);

        const heightsCopy = heights;
        let i = 0;
        while (i < sizeIndex - 1) {
            heights = heights.concat(heightsCopy);
            i++;
        }

        $.each(imageContainer, function (key, item) {
            $(item).find('.img').css('height', heights[key] + 'px');
        });
    };
};

new SelectorInitiator().setObject(new ImageHeightVariable());
new SelectorInitiator().setObject(new LoadExperiencesForExtendedWidget(
    new SelectorInitiator().getObjectBySelector('.image-height-variable')
));

const specialCard = typeof contactDetails !== 'undefined' ?
    $(`<section class="col-md-6 col-lg-4 special-card">
            <div class="special-card-block card-block-item appearance-effect">
                <strong class="special-card-block__title translatable">Not sure any of these are for you?</strong>
                <p class="special-card-block__description translatable">Get in touch and our team will make suggestions for your perfect experience based on you</p>
                <div class="contact-block">
                    <span class="contact-block__title basic-yellow">Anna Persson</span>
                    <p class="contact-block__description">Anna Persson | 
                        <span class="translatable">Co-founder</span>
                    </p>
                    <hr/>
                    <ul class="contact-block__list">
                        <li class="contact-block__item">
                            <i class="icon-envelope" aria-hidden="true"></i>
                            <span>
                                <a href='mailto:${contactDetails.emailHelpForm}' target=_blank" itemprop="email" content='${contactDetails.emailHelpForm}' title="click to email us">${contactDetails.emailHelpForm}</a>
                            </span>
                        </li>
                        <li class="contact-block__item">
                            <i class="icon-phone" aria-hidden="true"></i>
                            <span>
                                <a href='tel:${contactDetails.phoneNumber}' target="_blank" itemprop="phone" content='${contactDetails.phoneNumber}' title="click to call us">${contactDetails.phoneNumberFormatted}</a>
                            </span>
                        </li>
                        <li class="contact-block__item">
                            <i class="icon-whatsapp" aria-hidden="true"></i>
                            <span>
                                <a href="https://api.whatsapp.com/send?phone=${contactDetails.whatsappNumberFormatted}" target="_blank" itemprop="phone" title="click to chat to us">${contactDetails.whatsappNumber} (WhatsApp)</a>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </section>`)
    : '';

let ChangeAppearanceEffectExperienceCards = function(obj) {

    this.selector = '.appearance-effect-experiences';
    this.initEffect = 'appearance-effect';

    this.init = function () {

        $(obj).removeClass(this.initEffect);

        if (isMobile) {
            hoverEffectOnExperienceCardsForMobileDevices();
        }
    };

    let hoverEffectOnExperienceCardsForMobileDevices = () => {
        let self = this;

        document.addEventListener('scroll', function () {
            var centerDeviceScreenHeight = document.documentElement.clientHeight / 2;
            var cardsArray = $(self.selector).find('.card-block-item');

            cardsArray.each(function () {
                let cardParams = this.getBoundingClientRect();

                if (centerDeviceScreenHeight >= cardParams.top) {
                    $(this).find('.card-block-header__bg').css({'opacity': 1});
                } else {
                    $(this).find('.card-block-header__bg').css({'opacity': 0});
                }

                if (centerDeviceScreenHeight >= cardParams.top + cardParams.height / 2) {
                    $(this).find('.card-block-header__bg').css({'opacity': 0});
                }
            });
        });
    };
};

new SelectorInitiator().setObject(new ChangeAppearanceEffectExperienceCards('.card-block-item.appearance-effect'));
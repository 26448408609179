let ExperiencesFilters = function () {
    this.selector = '.general-filter';

    let loadWidget = new SelectorInitiator().getObjectBySelector('.see-more');
    let filterUrlTransformation;
    let countriesData = [];

    let selected = {
        countries: [],
        cities: [],
        categories: [],
        sortBy: 'undefined'
    };

    let isDirty = false; // make true if filters changes

    this.init = function() {

        const filterToTop = $('.general-filter').offset().top;

        if (typeof countries === 'undefined') {
            console.error('Var countries is undefined');
            return;
        }

        filterUrlTransformation =  new FilterUrlTransformation();
        countriesData = countries;

        checkBoxControls('.country-submenu-list');
        checkBoxControls('.city-submenu-list');
        checkBoxControls('.experience-submenu-list');
        checkBoxControls('.filters-mob__country');
        checkBoxControls('.filters-mob__city');
        checkBoxControls('.filters-mob__exp-type');
        sortBySelect('.sort-by__list');
        showMobileSubmenu();
        removeAllCheckedResults();

        // btn view experience filter (pc -v) =====
        $('.apply-filter').on('click', closeDesktopFilters);

        // btn view experience filter (mob -v) =====
        $('.filter-view-exp').on('click', closeMobileFilters);

        // btn close filter (mob -v) =====
        $('.filters-mob__menu__close__btn').on('click', closeMobileFilters);

        // btn sort (mob -v and pc -v) =====
        $('.sort-by__btn').on('change', function() {
            $('body').addClass('no-scroll');
            addSubstrate();
            hideBtnFilters();
        });

        // btn filters (mob -v and pc -v) =====
        $('.filters__btn').on('change', function() {
            $('body').addClass('no-scroll');
            addSubstrate();
        });

        $('.general-filter__buttons').find('input[type=radio]').on('change', function() {
            if (isMobile && !isScreenSizeMobile()) {
                $('body').animate({top: -(filterToTop) + 90}, 0);
            }

            else if (isMobile && isScreenSizeMobile()) {
                $('body, html').animate({scrollTop:
                        $('.general-filter').offset().top - ($('.main-header').height() + 75)
                }, 800);
            }

            else {
                $('body, html').animate({scrollTop:
                        $('.general-filter').offset().top - ($('.main-header').height() + 120)
                }, 800);
            }

            $('.general-filter').addClass('general-filter--opened');
            $('.general-filter__buttons__left').find('label').removeClass('active-filter__radio');
            changeFilterMenu(this);
        });

        if (window.location.search.length) {
            initSearchParamsFromUrl();
        }

        document.addEventListener('click', event => {
            if (isScreenSizeMobile()) {
                shouldSectionClosePC(event) ? setTimeout(() => {closeDesktopFilters()}, 0) : null;
            } else {
                shouldSectionCloseMB(event) ? setTimeout(() => {closeMobileFilters()}, 0) : null;
            }
        });
    };

    const shouldSectionClosePC = function(event) {
        let mainCountry, btnCountry, mainCity, btnCity, mainExperience, btnExperience;

        const countrySubmenu = document.querySelector('.country-submenu-list');
        const countryBtn = document.querySelector('.filters__btn-country');
        if (countryBtn) {
            mainCountry = countrySubmenu === event.target || countrySubmenu.contains(event.target);
            btnCountry = countryBtn === event.target || countryBtn.contains(event.target);
        } else {
            mainCountry = false;
            btnCountry = false;
        }
        
        const citySubmenu = document.querySelector('.city-submenu-list');
        const cityBtn = document.querySelector('.filters__btn-city');
        if (cityBtn) {
            mainCity = citySubmenu === event.target || citySubmenu.contains(event.target);
            btnCity = cityBtn === event.target || cityBtn.contains(event.target);
        } else {
            mainCity = false;
            btnCity = false;
        }
        
        const expSubmenu = document.querySelector('.experience-submenu-list');
        const expBtn = document.querySelector('.filters__btn-exp');
        if (expBtn) {
            mainExperience = expSubmenu === event.target || expSubmenu.contains(event.target);
            btnExperience = expBtn === event.target || expBtn.contains(event.target);
        } else {
            mainExperience = false;
            btnExperience = false;
        }

        const isActive = document.querySelector('.general-filter').classList.contains('general-filter--opened');
        
        const isMainClick = mainCountry || mainCity || mainExperience;
        const isBtnClick = btnCountry || btnCity || btnExperience;
        return !isMainClick && !isBtnClick && isActive;
    };

    const shouldSectionCloseMB = function() {
        let mainBtn = false, countryBtn = false, countryMain = false, cityBtn = false, cityMain = false, expBtn =false, expMain = false, sortBtn = false;

        mainBtn = document.querySelector('.filters-mob__btn') === event.target || document.querySelector('.filters-mob__btn').contains(event.target);

        const mobCountryBtn = document.querySelector('.mob-country');
        const mobCountryMain = document.querySelector('.filters-mob__country');
        if (mobCountryBtn) {
            countryBtn = mobCountryBtn === event.target || mobCountryBtn.contains(event.target);
            countryMain = mobCountryMain === event.target || mobCountryMain.contains(event.target);
        }

        const mobCityBtn = document.querySelector('.mob-city');
        const mobCityMain = document.querySelector('.filters-mob__city');
        if (mobCityBtn) {
            cityBtn = mobCityBtn === event.target || mobCityBtn.contains(event.target);
            cityMain = mobCityMain === event.target || mobCityMain.contains(event.target);
        }

        const mobExpBtn = document.querySelector('.mob-exp');
        const mobExpMain = document.querySelector('.filters-mob__exp-type');
        if (mobExpBtn) {
            expBtn = mobExpBtn === event.target || mobExpBtn.contains(event.target);
            expMain = mobExpMain === event.target || mobExpMain.contains(event.target);
        }

        const btn = countryBtn || cityBtn || expBtn;
        const main = countryMain || cityMain || expMain;

        const isActive = document.querySelector('.general-filter').classList.contains('general-filter--opened');

        return !mainBtn && !btn && !main && isActive;
    };

    const hideBtnFilters = function() {
        $('.sort-by__btn').css({'pointer-events': 'none', 'opacity': '0.5'});
        $('.filters__btn').css({'pointer-events': 'none', 'opacity': '0.5'});
    };

    const showBtnFilters = function() {
        $('.sort-by__btn').removeAttr('style');
        $('.filters__btn').removeAttr('style');
    };

    const addSubstrate = function() {
        const substrate = `<div class="substrate show"></div>`;
        document.querySelector('.substrate') ? null : document.body.insertAdjacentHTML('afterbegin', substrate);
    };

    const deleteSubstante = function() {
        $('.substrate').remove();
    };

    const scrollTopForMobile = function() {
        $('body, html').animate({scrollTop:
            $('.general-filter').offset().top - ($('.header').height() + 58)
        }, 0);
    };
    
    /**
     *
     * obj {this} - js object;
     */
    let changeFilterMenu = function(obj) {
        $('.country-submenu-list').css({'display': 'none'});
        $('.city-submenu-list').css({'display': 'none'});
        $('.experience-submenu-list').css({'display': 'none'});
        $('.sort-by__list').css({'display': 'none'});

        let $target = false;
        let $obj = $(obj);

        if ($obj.val() === 'country') {
            $target = $('.country-submenu-list');
            $obj.parent().addClass('active-filter__radio');
        } else if ($obj.val() === 'city') {
            $target = $('.city-submenu-list');
            $obj.parent().addClass('active-filter__radio');
        } else if ($obj.val() === 'exp-type') {
            $target = $('.experience-submenu-list');
            $obj.parent().addClass('active-filter__radio');
        } else if ($obj.val() === 'sort-by') {
            $target = $('.sort-by__list');
        } else if ($obj.val() === 'filters-mob') {
            $('.filters-mob').addClass('filters-mob--opened');
            $('.sort-by__list').css({'display': 'none'});
            noActiveMobileMainMenu();
        }

        $target ? $target.css({'display': 'block'}) : false;
    };

    /**
     * remove checked result item;
     *
     * id {string} - id or className;
     */
    let removeCheckedResult = function(id) {
        $('.filter__submenu__result-list > li > i').on('click', function() {

            let currentItem = $(this).parent().text();

            $('.filter__submenu__result-list').find('li').each(function() {

                if ($(this).text() === currentItem) {
                    $(this).remove();
                }

                $('.filter__submenu__result-list').find('li').each(function() {
                   if (currentItem === $(this).attr('data-country')) {
                       $(this).remove();

                       $('.city-submenu-list').find(':checked').each(function() {
                           if (currentItem === $(this).parent().attr('data-country')) {
                               $(this).prop('checked', false);
                           }
                       });

                       $('.filters-mob__city').find(':checked').each(function() {
                           if (currentItem === $(this).parent().attr('data-country')) {
                               $(this).prop('checked', false);
                           }
                       });
                   }
                });
            });

            $('.filter__submenu__list').find(':checked').each(function() {
                if ($(this).val() === currentItem) {
                    $(this).prop('checked', false);
                }
            });

            if (!$('.filter__submenu__result-list').find('li').length) {
                $('.clean-result__btn').css({'display': 'none'});
                $('.apply-filter').attr('disabled', 'disabled');
                $('.filter-view-exp').attr('disabled', 'disabled');
                $('.filter__submenu__result').css({'height': 'initial', 'padding': 'initial'});
                $('.filters-mob__menu').find('b').text('');
            }

            mobileCountSelectedItems();
            rebuildCitiesAccordingToCountries();
            isDirty = true;
            runFiltering();
        });
    };

    /**
     * remove all checked results 'Clear All'-btn;
     */
    let removeAllCheckedResults = function() {
        $('.clean-result__btn').off('click').on('click', function() {
            $('.filter__submenu__result-list').find('li').remove();
            $('.filter__submenu__list').find(':checked').each(function() {
                $(this).prop('checked', false);
            });

            $('.apply-filter').attr('disabled', 'disabled');
            $('.clean-result__btn').css({'display': 'none'});
            $('.filter-view-exp').attr('disabled', 'disabled');

            $('.filters-mob__menu').find('b').text('');
            $('.js-city-filter').find('.city-list').css({'display': 'block'});
            $('.filter__submenu__result').css({'height': 'initial', 'padding': 'initial'});
            isDirty = true;
            runFiltering();
        });
    };

    /**
     * checkbox watcher;
     *
     * id {string} - id or className;
     */
    let checkBoxControls = function(id) {
        $(id).find('input[type=checkbox]').on('change', function() {
            let $this = $(this);
            let checkboxVal = $this.val();

            if ($this.data('type') === 'country') {
                $('.js-city-filter').find('input:checked').prop('checked', false);
                $('.filter__submenu__result-list').find('[data-sort="2"]').each(function() {
                    $(this).remove();
                });

                rebuildCitiesAccordingToCountries();
            }

            // if checked;
            if ($this.is(':checked')) {
                let $remove = $('<li class="translatable" data-sort=' + $(this).attr('data-order') + '>' + $this.val() + '<i class="fa fa-times-circle-o" aria-hidden="true"></i></li>');
                $(this).parent().attr('data-country') ? $remove.attr('data-country', $(this).parent().attr('data-country')) : false;
                $('.filter__submenu__result-list').append($remove);

                sortResultListItems();
                removeCheckedResult(id);

                if ($(id).find(':checked').length) {
                    $('.apply-filter').removeAttr('disabled');
                    $(id).find('.filter-view-exp').removeAttr('disabled');
                }

                if ($('.filter__submenu__result-list').children().length) {
                    $('.filter__submenu__result').css({'height': 'auto', 'padding': '30px 10px'});
                    $('.clean-result__btn').css({'display': 'initial'});
                }
            }

            // if not checked;
            else {
                $('.filter__submenu__result-list').find('li').each(function() {
                    if ($(this).text() === checkboxVal) {
                        $(this).remove();
                    }

                    if (!$(id).find(':checked').length) {
                        $('.apply-filter').attr('disabled', 'disabled');
                        $(id).find('.filter-view-exp').attr('disabled', 'disabled');
                    }

                    if (!$('.filter__submenu__result-list').children().length) {
                        $('.clean-result__btn').css({'display': 'none'});
                    }
                });
            }
            mobileCountSelectedItems();
            isDirty = true;
        });
    };

    /**
     * close Desktop filtersMenu;
     */
    let closeDesktopFilters = function() {
        $('.general-filter').removeClass('general-filter--opened');
        $('.general-filter__buttons__left').find('label').removeClass('active-filter__radio');
        $('.general-filter__buttons__left').find('input[type=radio]').prop('checked', false);
        $('.general-filter__buttons__right').find('input[type=radio]').prop('checked', false);
        $('.country-submenu-list').css({'display': 'none'});
        $('.city-submenu-list').css({'display': 'none'});
        $('.experience-submenu-list').css({'display': 'none'});
        $('.sort-by__list').css({'display': 'none'});
        $('body').removeClass('no-scroll');
        showBtnFilters();
        runFiltering();
        deleteSubstante();
    };

    /**
     * sort-by select-btn;
     *
     * id {string} - id or className;
     */
    let sortBySelect = function(id) {
        $(id).find('input[type=radio]').on('click', function() {
            if ($('#sort-by-btn').data('order') !== $(this).data('id')) {
                $('.sort-by__btn').find('span').text($(this).parent().find('span').text().substring(0, 18));
                $('.sort-by__list').css({'display': 'none'});
                $('#sort-by-btn').prop('checked', false);
                $('#sort-by-btn').data('order', $(this).data('id'));

                if (isMobile && !isScreenSizeMobile()) {
                    $('body, html').animate({
                        scrollTop: $('.general-filter').offset().top - ($('.header').height() + 58)
                    }, 0);
                }
                isDirty = true;
            }
        });
    };

    /**
     * close Mobile filtersMenu;
     */
    let closeMobileFilters = function() {
        $('.general-filter').removeClass('general-filter--opened');
        $('.filters-mob').removeClass('filters-mob--opened');
        $('.filters-mob__menu__close').find('span').text('Filters');
        $('.general-filter__buttons__right').find('input[type=radio]').prop('checked', false);
        $('.filters-mob__submenu').css({'display': 'none'});
        $('.sort-by__list').css({'display': 'none'});
        $('.general-filter').children('h5').css({'opacity': 'initial'});
        $('.filters__btn-long').css({'opacity': 'initial', 'pointer-events': 'initial'});
        $('.main-result').css({'opacity': 'initial', 'pointer-events': 'initial'});
        $('body').removeClass('no-scroll');
        
        scrollTopForMobile();
        runFiltering();
        deleteSubstante();
    };

    /**
     * no-active mobile main-menu;
     */
    let noActiveMobileMainMenu = function() {
        $('.general-filter').children('h5').css({'opacity': '0.5'});
        $('.filters__btn-long').css({'opacity': '0.5', 'pointer-events': 'none'});
        $('.main-result').css({'opacity': '0.5', 'pointer-events': 'none'});
    };

    /**
     * show mobiles sub-Menus;
     */
    let showMobileSubmenu = function() {
        $('.filters-mob__menu').find('li').on('click', function() {
            $('.filters-mob__menu__close').find('span').text($(this).attr('data-value'));

            if ($(this).attr('data-value') === 'Filter by country') {
                $('.filters-mob__country').css({'display': 'block'});
            }

            if ($(this).attr('data-value') === 'Filter by city') {
                $('.filters-mob__city').css({'display': 'block'});
            }

            if ($(this).attr('data-value') === 'Filter by experience type') {
                $('.filters-mob__exp-type').css({'display': 'block'});
            }
        });
    };

    /**
     * counter on mobile filter menu;
     */
    let mobileCountSelectedItems = function() {
        $('.filters-mob__menu').find('b').text('');

        if ($('.filters-mob__country').find(':checked').length || $('.filters-mob__city').find(':checked').length || $('.filters-mob__exp-type').find(':checked').length) {
            $('.mob-country').find('b').text($('.filters-mob__country').find(':checked').length ? '(' + $('.filters-mob__country').find(':checked').length + ' selected)' : '');
            $('.mob-city').find('b').text($('.filters-mob__city').find(':checked').length ? '(' + $('.filters-mob__city').find(':checked').length + ' selected)' : '');
            $('.mob-exp').find('b').text($('.filters-mob__exp-type').find(':checked').length ? '(' + $('.filters-mob__exp-type').find(':checked').length + ' selected)' : '');
        }
    };

    let rebuildCitiesAccordingToCountries = function() {
        let cities = getCitiesByCountries(getCheckedCountries());
        if (cities.length) {
            $('.js-city-filter').find('.city-list').css({'display': 'none'});

            $.each(cities, (key, value) => {
                $('.js-city-filter').find('[data-city="' + value + '"]').css({'display': 'block'});
            });
        } else {
            $('.js-city-filter').find('.city-list').css({'display': 'block'});
        }
    };

    /**
     * @return {array} selectedCountries
     */
    let getCheckedCountries = function() {
        let selectedCountries = [];
        $.each($('.js-country-filter').find('input:checked'), (key, value) => {
            selectedCountries.push($(value).data('url'));
        });

        return selectedCountries;
    };

    /**
     * @return {array} selectedCities
     */
    let getCheckedCities = function() {
        let selectedCities = [];
        $.each($('.js-city-filter').find('input:checked'), (key, value) => {
            selectedCities.push($(value).data('id'));
        });

        return selectedCities;
    };

    /**
     * @return {array} selectedCategories
     */
    let getCheckedCategories = function() {
        let selectedCategories = [];
        $.each($('.js-category-filter').find('input:checked'), (key, value) => {
            selectedCategories.push($(value).data('url'));
        });

        return selectedCategories;
    };

    /**
     * @param {string} countrySlug
     * @return {array} cities
     */
    let getCountryBySlug = function(countrySlug) {
        let country = [];
        $.each(countriesData, (key, value) => {
            if (value.countrySlug === countrySlug) {
                country = value;
            }
        });

        return country;
    };

    /**
     * @return {int}
     */
    let getCheckedSortBy = function() {
        return $('#sort-by-btn').data('order');
    };

    /**
     * @param {array} countriesSlugs
     * @param {bool} getId
     * @return {array} cities
     */
    let getCitiesByCountries = function(countriesSlugs, getId = false) {
        let cities = [];
        $.each(countriesSlugs, (key, slug) => {
            $.each(getCountryBySlug(slug)['cities'], (key, value) => {
                if (getId) {
                    cities.push(value.id);
                } else {
                    cities.push(value.location_url);
                }
            });
        });

        return cities;
    };

    /**
     * @return {void}
     */
    let runFiltering = function() {
        if (isDirty) {
            loadWidget.btn.attr('href', getUrl());
            loadWidget.reloadExperiences();
            isDirty = false;
        }
    };

    /**
     * @return {void}
     */
    let initSearchParamsFromUrl = function() {
        selected = filterUrlTransformation.readableUrlToSelected(window.location.search, selected);

        $.each(selected.cities, (key, val) => {
            isMobile ? $('.filters-mob__city').find('[data-id=' + val + ']').prop('checked', 'checked') : $('.city-submenu-list').find('[data-id=' + val + ']').prop('checked', 'checked');
        });
        isMobile ? mobileCountSelectedItems() : false;

        $.each(selected.countries, (key, val) => {
            isMobile ? $('.filters-mob__country').find('[data-url=' + val + ']').prop('checked', 'checked') : $('.country-submenu-list').find('[data-url=' + val + ']').prop('checked', 'checked');
        });
        isMobile ? mobileCountSelectedItems() : false;

        $.each(selected.categories, (key, val) => {
            isMobile ? $('.filters-mob__exp-type').find('[data-url=' + val + ']').prop('checked', 'checked') : $('.experience-submenu-list').find('[data-url=' + val + ']').prop('checked', 'checked');
        });
        isMobile ? mobileCountSelectedItems() : false;

        if (selected.sortBy !== 'undefined') {
            let category = $('.sort-by__list').find('[data-id=' + selected.sortBy + ']');
            if (category.length) {
                $('.sort-by__btn').find('span').text(category.val());
                category.prop('checked', 'checked');
                $('#sort-by-btn').data('order', category.data('id'));
            }
        }

        showInitSearchFormResults('.country-submenu-list');
        showInitSearchFormResults('.city-submenu-list');
        showInitSearchFormResults('.experience-submenu-list');

        showInitSearchFormResults('.filters-mob__country');
        showInitSearchFormResults('.filters-mob__city');
        showInitSearchFormResults('.filters-mob__exp-type');
        isDirty = true;
        runFiltering();
    };

    let getSelectedData = function () {
        selected.cities = getCheckedCities();
        selected.countries = getCheckedCountries();
        selected.categories = getCheckedCategories();
        selected.sortBy = getCheckedSortBy();
    };

    /**
     * @return {string}
     */
    let getUrl = function() {
        let urlGetParams = '';

        getSelectedData();

        let newSearchUrl = window.location.origin + window.location.pathname + '?' + filterUrlTransformation.selectedToReadableUrl(selected);
        window.history.pushState({path: newSearchUrl}, '', newSearchUrl);
        updateURL();

        let selectedCities = selected.cities;

        if (!selectedCities.length) {
            selectedCities = getCitiesByCountries(selected.countries, true);
        }
        selectedCities.forEach((id) => {
            urlGetParams += ('locationIds[]=' + id + '&');
        });

        if (!urlGetParams.length) {
            urlGetParams = loadWidget.btn.data('defaulturl');
        } else {
            urlGetParams = loadWidget.btn.data('url') + urlGetParams;
        }

        selected.categories.forEach((id) => {
            urlGetParams += ('categoryIds[]=' + id + '&');
        });

        if (selected.sortBy !== 'undefined') {
            urlGetParams += ('orderBy=' + selected.sortBy + '&');
        }

        return urlGetParams;
    };

    /**
     * sort checked results country -> city -> experience;
     */
    let sortResultListItems = function() {
        let resItems = $('.main-result-list li');
        let resArrItems = $.makeArray(resItems);

        resArrItems.sort(function(a, b) {
            return $(a).data('sort') - $(b).data('sort');
        });

        $('.filter__submenu__result-list').find('li').remove();
        $(resArrItems).appendTo('.filter__submenu__result-list');
    };

    /**
     * show saved checked results;
     *
     * id {string} - id or className;
     */
    let showInitSearchFormResults = function(id) {
        $(id).find('input[type=checkbox]:checked').each(function() {

            let $remove = $('<li class="translatable" data-sort=' + $(this).attr('data-order') + '>' + $(this).val() + '<i class="fa fa-times-circle-o" aria-hidden="true"></i></li>');
            $('.filter__submenu__result-list').append($remove);
        });

        if ($('.main-result-list').children().length) {
            $('.clean-result__btn').css({'display': 'initial'});
            $('.apply-filter').removeAttr('disabled');
            $('.filter-view-exp').removeAttr('disabled');
        }

        removeCheckedResult(id);
    };
};


let FilterUrlTransformation = function () {
    if (typeof countries === 'undefined') {
        console.error('Var countries is undefined');
        return;
    }

    let cities = [];

    countries.forEach(country => {
        country.cities.forEach(city => cities.push(city));
    });

    let categories = sampleCategories.map(category => {
        return {
            name: category.name.replace(/\s/g, '').replace('&', '').toLowerCase(),
            id: category.id
        };
    });

    /**
     *
     */
    this.selectedToReadableUrl = function (selected) {
        let readableUrl = '';
        readableUrl += selected.countries.length ? '&countries=' + selected.countries.join('@') : '';
        readableUrl += selected.cities.length ? '&cities=' + selected.cities.map(id => cities.find(city => city.id === parseInt(id, 10)).location_url).join('@') : '';
        readableUrl += selected.categories.length ? '&category=' + selected.categories.map(id => categories.find(category => category.id === parseInt(id, 10)).name).join('@') : '';

        return readableUrl;
    };

    this.readableUrlToSelected = function (url, selected) {
        let hrefData = new URL(window.location.origin + url);

        let countriesHref = hrefData.searchParams.get('countries') ? hrefData.searchParams.get('countries').split('@') : [];
        selected.countries = countriesHref.length ? countriesHref
            .map(countrySlug => countries.find(country => country.countrySlug === countrySlug))
            .filter(country => typeof country !== 'undefined')
            .map(country => country.countrySlug) : [];

        let citiesHref = hrefData.searchParams.get('cities') ? hrefData.searchParams.get('cities').split('@') : [];
        selected.cities = citiesHref.length ? citiesHref
                .map(locationUrl => cities.find(city => city.location_url === locationUrl))
                .filter(location => typeof location !== 'undefined')
                .map(location => location.id) : [];

        let categoriesHref = hrefData.searchParams.get('category') ? hrefData.searchParams.get('category').split('@') : [];
        selected.categories = categoriesHref.length ? categoriesHref
            .map(categoryStr => categories.find(category => category.name === categoryStr))
            .filter(category => typeof category !== 'undefined')
            .map(category => category.id) : [];

        return selected;
    };
};

new SelectorInitiator().setObject(new ExperiencesFilters());
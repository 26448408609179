'use strict';

const FactCardBuilder = function () {
    const fact =
        `<div class="col-md-6 col-lg-4" style="margin-bottom: 50px">
            <section class="fact-card fact-cr card-block-item appearance-effect">
                <strong class="fact-card__title translatable" name="title"></strong>
                <p class="fact-card__text translatable" name="text"></p>
                <i class="fact-card__doodle" name="doodle"></i>
            </section>
        </div>`;

    const review =
        `<div class="col-md-6 col-lg-4" style="margin-bottom: 50px">
            <section class="fact-card reviews-cr card-block-item appearance-effect">
                <p class="fact-card__text translatable" name="text"></p>
                <div class="fact-card__author-date">
                    <p class="author" name="author"></p>
                    <span>|</span>
                    <p class="date translatable" name="date"></p>
                </div>
                <i class="fact-card__doodle" name="doodle"></i>
            </section>
        </div>`;

    const tip =
        `<div class="col-md-6 col-lg-4" style="margin-bottom: 50px">
            <section class="fact-card tip-cr card-block-item appearance-effect">
                <strong class="fact-card__title translatable" name="title"></strong>
                <p class="fact-card__text translatable" name="text"></p>
                <i class="fact-card__doodle" name="doodle"></i>
            </section>
        </div>`;

    const pocketGuide =
        `<article class="col-md-6 col-lg-4 pocket-guide" style="margin-bottom: 50px">
            <div class="card-block-item pocket-guide-item bc-green appearance-effect">
                <h3>Free pocket guide!</h3>
                <p class="pocket-guide__body"></p>
                <span class="error-message"></span>
            
                <div class="user-guide-block" data-locationid="">
                    <section class="user-guide-block__preview">
                        <img src="` + ASSETSPATH + `/theme/_build/assets/images/doodles/fact-cards/pocked-guide-phone/pocket-guide-phone.png" alt="mobile-phone">
                        <img class="guide-preview" src="` + ASSETSPATH + `/theme/_build/assets/images/doodles/fact-cards/pocked-guide-phone/default-pocket-guide.jpg" alt="pocket guide preview">
                    </section>
                    
                    <section class="user-guide-block__info">
                        <label for="user-guide-email">
                            <input type="email"
                                   name="email"
                                   class="user-guide-email"
                                   tabindex="0"
                                   autocomplete="email"
                                   inputmode="email"
                                   placeholder="Enter your email">
                        </label>
                
                        <label for="user-guide-name">
                            <input type="text"
                                   name="first_name"
                                   class="user-guide-name"
                                   tabindex="0"
                                   inputmode="verbatim"
                                   placeholder="Enter your first name">
                        </label>
                
                        <button class="bc-red guide-send-btn">Send me the guide</button>
                    </section>
                </div>
            </div>    
        </article>`;

    const colors = [
        'green', 'blue', 'red', 'yellow',
    ];


    this.buildOne = function (item) {
        let card;

        if (item.type === 'pocket_guide') {
            card = $(pocketGuide);
            card.find('.pocket-guide__body').html(item.body);
            card.find('.user-guide-block').attr('data-locationid', item.site_location_id);
            if (item.documentImagePreviewForPocketGuide) {
                card.find('.guide-preview').attr('src', item.documentImagePreviewForPocketGuide);
            }
        } else if (item.type === 'tip') {
            card = $(tip);
            card.find('[name=title]').html(item.title);
            card.find('[name=text]').html(item.body);

        } else if (item.type === 'review') {
            card = $(review);
            card.find('[name=text]').html(item.body);
            card.find('[name=author]').html(item.author);
            card.find('[name=date]').html(this.changeViewDate(item.date));

        } else if (item.type === 'fact') {
            card = $(fact);
            card.find('[name=title]').html(item.title);
            card.find('[name=text]').html(item.body);
        } else {
            return $();
        }

        return card;
    };

    // @params items [{key:value}] @return [$]
    this.getMany = function (items) {
        let $cards = [];
        let self = this;
        $.each(items, function (key, val) {
            let singleCard = self.buildOne(val);
            let colorKey = key > colors.length - 1 ? key - colors.length : key;
            let color = colors[colorKey];

            singleCard.find('section').addClass('bc-' + color);
            singleCard.find('[name=doodle]').css('background', `url(${doodlePath}/${citiesLocation}/${citiesLocation}-fact-card.png) no-repeat center`);
            if (color === 'yellow') {
                singleCard.find('[name=title]').css({'color': 'var(--green-basic)'});
            }

            $cards.push(singleCard);
        });

        new PocketGuideCard().initEvents($cards.filter( value => {
            return value.hasClass('pocket-guide');
        }));

        return $cards;
    };

    this.changeViewDate = function(date) {
        const postDate = new Date(date);
        const monthAndYear = postDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });
        return monthAndYear;
    };
};

let PocketGuideCard = function() {

    let allCard = [];
    this.initEvents = function (cards) {
        allCard = cards;
        allCard.forEach(card => {

            let sendMeGuideButton = card.find('.guide-send-btn');

            sendMeGuideButton.on('click', function(e) {
                const errorMessages = validateSendMeTheGuide(e.target);
                if (errorMessages.length) {
                    card.find('.error-message').text(errorMessages);
                    return false;
                }
                sendMeGuide(card);
                return true;
            });

        });

        //set clearErrorMessage() on change inputs event
    };

    let sendMeGuide = function (obj) {
        $.ajax({
            url: '/send-guide-to-user',
            type: 'POST',
            data: {
                'email': obj.find('.user-guide-email').val(),
                'first_name': obj.find('.user-guide-name').val(),
                'site_location_id': obj.find('.user-guide-block').data('locationid'),
            },
            dataType: 'json',
            success: function (response) {
                allCard.forEach(card => {
                    card.find('.pocket-guide-item').addClass('guide-already-sent');
                });
            },
            error: function (response) {
                obj.find('.error-message').text(response.responseText);
                return false;
            }
        });
        return true;
    };

    let validateSendMeTheGuide = function (eventTarget) {
        const emailValidationErrorMessage = getEmailValidationErrorMessage(eventTarget.parentNode.querySelector('.user-guide-email'));
        const nameValidationErrorMessage = getLengthValidationErrorMessage(eventTarget.parentNode.querySelector('.user-guide-name'), {'max': 25, 'min': 2});

        return (emailValidationErrorMessage || nameValidationErrorMessage) ? emailValidationErrorMessage + ' ' + nameValidationErrorMessage : '';
    };
};
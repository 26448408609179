'use strict';

let maxDate = new Date();
maxDate.setMonth(maxDate.getMonth() + 18); // 18 month

function updateClaendarTitle() {
	$('#calendar .datepicker--nav-title').text( $('#calendar .datepicker--nav-title').text().split(',')[0] + ' ' + $('#calendar .datepicker--nav-title').text().split(',')[1] )
}

function updateCalendarTitleWithSelectedDate(isSelect = false) {
    let text = '';
    let dateWithoutEnding = '';
    let decideLaterOption = $('#date_decide_later');
    let dateText = $('.date-text');
    const minDaysMustChooseTime = 14;
    $('#calendar-error').text('').css('display', 'none');
    if (decideLaterOption.is(':checked')) {
        text = decideLaterOption.next('label').children('span').text();
    } else if ($('#calendar .-selected-').length) {
        let day = $('#calendar .datepicker--cell-day.-selected-').text();
        let previousSelectedDay = $('[class="date-text"]').text().replace('th', '').replace('rd', '').replace('nd', '').replace('st', '').replace(',', '').split(' ');

        if (!day || day.length === 0) {
            day = previousSelectedDay[0];
        }

        let [month, year] = $('#calendar .datepicker--nav-title').text().replace(',', '').split(' ').filter(Boolean);
        dateWithoutEnding = month + ' ' + day + ' ' + year;

        if (day === '11' || day === '12' || day === '13') {
            day += 'th';
        } else {
            switch (day[day.length - 1]) {
                case '1': day += 'st'; break;
                case '2': day += 'nd'; break;
                case '3': day += 'rd'; break;
                default : day += 'th';
            }
        }

        text = month + ' ' + day + ' ' + year;
    } else {
        text = dateText.data('text');
    }

    dateText.text(text);

    if (isMobile && $('.js-experience-date-start-time').length) {
        updateUnitedCalenarStartTimeField();
    }

    const choosenDate = new Date(dateWithoutEnding);
    const currentDate = new Date();
    const difference = choosenDate - currentDate;
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
    const timeBlock = $('#time');
    if(text === undefined || text === '') {
        $('.start-time, .united-start-time').text(`Start time`);
    } else {
        if (daysDifference < minDaysMustChooseTime) {
            $('.united-start-time').text('Select time');
            timeBlock.find('.time-item').first().addClass('hidden').removeClass('active');
            if (isSelect && !timeBlock.find('.active').length) {
                $('.start-time').text(`Select time`);
            }
        } else {
            $('.js-under-start-time-text').text('Start time');
            timeBlock.find('.time-item').first().removeClass('hidden');
            if (isSelect && !timeBlock.find('.active').length) {
                const firstStartTimeInList = timeBlock.find('.time-item').first();
                firstStartTimeInList.addClass('active');
                if (timeBlock.find('input[value="later"]').length) {
                    $('.start-time, .united-start-time').text(`I'll decide later`);
                } else {
                    $('.start-time, .united-start-time').text(timeBlock.find('input:radio').val());
                }
            }
        }
    }
}

function closeDatePicker() {
    $('.accordion.expanded').addClass('active');
    $('.accordion').removeClass('expanded');
}

function disableCloseDatesForDatePicker() {
    let calendarDates = $('.datepicker--cell-day');

    $.each(calendarDates, function (key, itemDate) {
        let $dateItem = $(itemDate);
        let date = parseInt($dateItem.data('date'), 10);
        let month = parseInt($dateItem.data('month'), 10);
        let year = parseInt($dateItem.data('year'), 10);
        let dateItemAsDate = new Date(year + '-' + (month + 1) + '-' + date);

        if (typeof closedDates !== 'undefined' && closedDates.length) {
            for (let key in closedDates) {
                let closeDate = new Date(closedDates[key]);
                if (
                    dateItemAsDate.getDate() === closeDate.getDate() &&
                    dateItemAsDate.getMonth() === closeDate.getMonth() &&
                    dateItemAsDate.getFullYear() === closeDate.getFullYear()
                ) {
                    $dateItem.addClass('-disabled-');
                }
            }
        }

        if (typeof availableClosedWeekDays !== 'undefined' && availableClosedWeekDays.length) {
            if ($.inArray(availableClosedWeekDays[dateItemAsDate.getDay()], closedWeekDays) !== -1) {
                $dateItem.addClass('-disabled-');
            }
        }
    });
}

const CalendarConfiguration = function () {
    this.selector = '';
    this.selectedDate = new Date();
    this.initScript = function () {
        return true;
    };
    this.onSelectDate = function () {
        return true;
    };
    this.onChangeMonth = function () {
        return true;
    };
};

let ConfigGenerator = function () {
    let configs = [];
    this.setConfig = function (calendarConfiguration) {
        configs.push(calendarConfiguration);
    };
    this.getCalendarConfig = function () {
        for (let key in configs) {
            if ($(configs[key].selector).length) {
                return configs[key];
            }
        }
        return new CalendarConfiguration();
    };
};

let calendarConfigBooking = new CalendarConfiguration();
calendarConfigBooking.selector = '#booking-form';
calendarConfigBooking.initScript = function () {
    setDataBookingWidgetFromCookie();
    let selectedDate = getCookie('bookingFormSelectedDate');
    if (selectedDate) {
        let selectedDateCookie = getDateFromString(selectedDate);
        if (!isNaN(selectedDateCookie.getTime())) {
            this.selectedDate = selectedDateCookie;
            updateCalendarTitleWithSelectedDate();
        }
    }
    updatePrice();
    disableCloseDatesForDatePicker();
};
calendarConfigBooking.onSelectDate = function() {
    let dateText = $('#calendar').data('date');

    if (dateText) {
        let selectedDate = getDateFromString(dateText);
        let lifeTime = new Date(selectedDate);
        lifeTime.setDate(selectedDate.getDate() - 1);
        let today = new Date();
        if (lifeTime <= today){
            lifeTime.setDate(today.getDate() + 1);
        }
        setDataToCookie(BookingWidgetNameForSelectedDate, dateText, lifeTime);
        updatePrice();
        $('button.not-active').removeAttr('disabled');
        return true;
    }
    this.selectedDate = dateText;
    $('.date-text').text($('.date-text').data('text'));
    return false;
};

calendarConfigBooking.onChangeMonth = function() {
    if ($('.js-buy-as-gift-checkbox').prop('checked')) {
        closedDatesForDatePickerForGift(true);
    } else {
        closedDatesForDatePickerForGift(false);
    }

    disableCloseDatesForDatePicker();
};

let calendarConfigHostRequest = new CalendarConfiguration();
calendarConfigHostRequest.selector = '#host-page';
calendarConfigHostRequest.initScript = function () {};

let calendarConfigReviewForm = new CalendarConfiguration();
calendarConfigReviewForm.selector = '#review_form';
calendarConfigReviewForm.initScript = function () {
    updateCalendarTitleWithSelectedDate();
    let date = new Date($('#calendar').data('date'));
    $('#created_at').val(dateToString(date));
};
calendarConfigReviewForm.onSelectDate = function () {
    let date = new Date($('#calendar').data('date'));
    $('#created_at').val(dateToString(date));
    return true;
};

let calendarConfigVirtualBooking = new CalendarConfiguration();
calendarConfigVirtualBooking.selector = '.js-virtual-booking-form';
calendarConfigVirtualBooking.initScript = function () {
    let selectedDate = getCookie('bookingFormSelectedDate');
    if (selectedDate) {
        let selectedDateCookie = getDateFromString(selectedDate);
        if (!isNaN(selectedDateCookie.getTime())) {
            this.selectedDate = selectedDateCookie;
            const calendar = $('#calendar');
            calendar.data('date',selectedDate);
            calendar.val(selectedDate);
            selectedDate = getDateFromString(selectedDate);
            calendar.find(
                '[data-date="' + selectedDate.getDate() + '"]' +
                '[data-month="'+ selectedDate.getMonth() +'"]' +
                '[data-year="'+ selectedDate.getFullYear() +'"]'
            ).addClass('-selected-');
            updateCalendarTitleWithSelectedDate();
        }
    }

    disableCloseDatesForDatePicker();
};

calendarConfigVirtualBooking.onSelectDate = function () {
    updateTimesByTimeZone();
    return true;
};

/* === for the 'Gift Builder Form' datepicker === */
let giftBuilderForm = new CalendarConfiguration();
giftBuilderForm.selector = '.gift-builder-form';
giftBuilderForm.initScript = function () {};


let configGenerator = new ConfigGenerator();
configGenerator.setConfig(calendarConfigBooking);
configGenerator.setConfig(calendarConfigHostRequest);
configGenerator.setConfig(calendarConfigReviewForm);
configGenerator.setConfig(calendarConfigVirtualBooking);
configGenerator.setConfig(giftBuilderForm);

$(window).on('load', () => {

    if (document.getElementById('calendar')) {

        const config = configGenerator.getCalendarConfig();

        let startDate = new Date();
        let dateFromCookie = getCookie(BookingWidgetNameForSelectedDate);
        if (dateFromCookie && getDateFromString(dateFromCookie)) {
            let selectedDateCookie = getDateFromString(dateFromCookie);
            if (!isNaN(selectedDateCookie.getTime())){
                startDate = new Date(selectedDateCookie.getFullYear(), selectedDateCookie.getMonth(), selectedDateCookie.getDate());
            }
        }

        $('#calendar').datepicker({
            startDate: startDate,
            language: 'en',
            minDate: $('#calendar').data('closepast') === true ? new Date(new Date().getTime() + 86400000) : null, // current date +1 day or NULL
            maxDate: $('#calendar').data('closefuture') === true ? new Date() : maxDate, // max date can you choose 18 month

            onSelect: function onSelect(fd, date, inst) {
                if (fd) {
                    $('#date_decide_later').prop('checked', false);
                    $('#calendar').data('date', fd);
                    setImmediate(function () {
                        updateCalendarTitleWithSelectedDate(true);
                        if (config.onSelectDate()) {
                            closeDatePicker();
                        }
                    });
                    $('.close-calendar-icon').removeAttr('hidden');
                    $('.calendar-icon').attr('hidden', 'hidden');
                }
                setImmediate(function () {
                    disableCloseDatesForDatePicker();
                });
            },

            onChangeMonth: function onSelect(month, year) {
                updateClaendarTitle();
                config.onChangeMonth();
                if (!$('.-selected-').length && config.selectedDate && !$('#date_decide_later').prop('checked')) {
                    $("[data-date=" + config.selectedDate.getDate() + "][data-month=" + config.selectedDate.getMonth() + "]").addClass('-selected-');
                }
                disableCloseDatesForDatePicker();
            },

            onChangeView: function(view) { // @var view {string} - view name, to which transition is done (days, months, years).
                if (view === 'days') {
                    disableCloseDatesForDatePicker();
                }
            }
        });

        $('.close-calendar-icon').on('click', function () {
            $('#calendar').data('datepicker').clear();
            $('#calendar').data('date', '');
            $('.date-text').text($('.date-text').data('text'));
            $(this).attr('hidden', 'hidden');
            $('.calendar-icon').removeAttr('hidden');
        });

        $(document).click(function (event) {
            if ($(event.target).find('#calendar').length !== 0 && $('.accordion').hasClass('expanded')) {
                closeDatePicker();
            }
        });

        updateClaendarTitle();

        config.initScript();

        $('.js-apply-calendar').on('click', (e) => {
            e.preventDefault();
            updateCalendarTitleWithSelectedDate();
        });

        $('.js-clear-calendar').on('click', (e) => {
            e.preventDefault();

            $('#calendar .-selected-').removeClass('-selected-');
            $('.date-text').text($('.date-text').data('text'));
            $('.accordion').removeClass('active expanded');
        });

        $('#date_decide_later').on('change', function (e) {
            if ($(this).is(':checked')) {
                $('#calendar').data('datepicker').clear();
                $('#calendar').data('date', '');
            }
            updatePrice();
            disableCloseDatesForDatePicker();
            updateCalendarTitleWithSelectedDate();
            closeDatePicker();
        });

        $('.js-buy-as-gift-checkbox').on('change', function () {
            if ($('#calendar .-selected-').length) {
                let text;
                const dateText = $('.date-text');

                $('#calendar').data('datepicker').clear();
                $('#calendar').data('date', '');

                text = 'Experience date';
                dateText.text(text);

                if (isMobile && $('.js-experience-date-start-time').length) {
                    updateUnitedCalenarStartTimeField();
                }
            } else {
                $('#calendar-error').text('').css('display', 'none');
            }

            if ($(this).prop('checked')) {
                closedDatesForDatePickerForGift(true);
            } else {
                closedDatesForDatePickerForGift(false);
            }

            disableCloseDatesForDatePicker();
        });
    }
});

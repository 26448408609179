'use strict';

// clear error messages;
function clearErrorMessages(obj, borderColor) {
    var err = document.getElementById(obj.id + '-message');

    if (err.innerHTML !== '') {
        err.innerHTML = '';
        obj.style.borderColor = borderColor? borderColor : '#CDCFE1';
        obj.style.transition = 'border-color .5s';
    }
}
function clearErrorAndStyle(element) {
    if ($(element).attr('style')) {
        $(element).removeAttr('style');
    }
}
// validation text length;
const getLengthValidationErrorMessage = function(obj, params) {
    var errorMessage = '';

    var minLengthValue = 2;
    if (!$.isEmptyObject(params) && 'min' in params) {
        minLengthValue = parseInt(params.min, 10);
    }

    var maxLengthValue = 50;
    if (!$.isEmptyObject(params) && 'max' in params) {
        maxLengthValue = parseInt(params.max, 10);
    }

    let elementName = $(obj).attr('data-fieldname');
    if (typeof elementName === 'undefined') {
        elementName = 'Field';
    }

    var value = $(obj).val();

    value = value.replace(/\s/g, '');
    if (value.length < minLengthValue) {
        errorMessage = elementName + ' must be at least ' + minLengthValue + ' characters';
    }

    if (value.length > maxLengthValue) {
        errorMessage = elementName + ' must be not more than ' + maxLengthValue + ' characters';
    }

    return errorMessage;
};
const getLengthTextFieldValidationErrorMessage = function(obj, params) {
    var errorMessage = '';

    var minLengthValue = 2;
    if (!$.isEmptyObject(params) && 'min' in params) {
        minLengthValue = parseInt(params.min, 10);
    }

    var maxLengthValue = 50;
    if (!$.isEmptyObject(params) && 'max' in params) {
        maxLengthValue = parseInt(params.max, 10);
    }

    let elementName = $(obj).attr('data-fieldname');
    if (typeof elementName === 'undefined') {
        elementName = 'Field';
    }

    var value = $(obj).text();

    value = value.replace(/\s/g, '');
    if (value.length < minLengthValue) {
        errorMessage = elementName + ' must be at least ' + minLengthValue + ' characters';
    }

    if (value.length > maxLengthValue) {
        errorMessage = elementName + ' must be not more than ' + maxLengthValue + ' characters';
    }

    return errorMessage;
};

// validation Email;
const getEmailValidationErrorMessage = function(obj) {
    const regExForEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email = $(obj).val();
    let errorMessage = '';

    if ((!regExForEmail.test(email)) || (email.length < 6)) {
        errorMessage = 'Enter valid Email';
    }

    return errorMessage;
};

// validation numeric length;
const getNumericalityValidationErrorMessage = function(obj, params) {
    var errorMessage = '';

    var maxValue = 50;
    var minValue = 0;

    if (!$.isEmptyObject(params)) {
        if ('max' in params) {
            maxValue = parseInt(params.max, 10);
        }

        if ('min' in params) {
            minValue = parseInt(params.min, 10);
        }
    }

    let elementName = $(obj).attr('data-fieldname');
    if (typeof elementName === 'undefined') {
        elementName = 'Field';
    }

    if (typeof maxValue !== 'undefined' && obj.value > maxValue) {
        errorMessage = elementName + ' must be not more than ' + maxValue;
    }

    if (typeof minValue !== 'undefined' && obj.value < minValue) {
        errorMessage = elementName + ' must be not less than ' + minValue;
    }

    return errorMessage;
};

const getSelectRequiredValidationErrorMessage = function(obj) {
    var errorMessage = '';

    if ($(obj).val().length < 1) {
        var elementName = $(obj).attr('data-fieldname');
        if (typeof elementName === 'undefined') {
            elementName = 'Selected element';
        }
        errorMessage = elementName + ' is required';
    }

    return errorMessage;
};
const getRequiredValidationErrorMessage = function(obj) {
    var errorMessage = '';

    if ($(obj).val().length < 1) {
        var elementName = $(obj).attr('data-fieldname');

        errorMessage = elementName + ' is required';
    }

    return errorMessage;
};

// validation selected length;
const getSelectLengthValidationErrorMessage = function(obj) {
    var errorMessage = '';

    if (!$('#flexible').find('input[type="radio"]:checked').length) {

        var elementName = $('#is_your_date_flexible').text().replace('*', '');

        errorMessage = elementName + ' is required';
    }

    return errorMessage;
};

// validation radio length;
const validateFieldRadio = function() {
    var errorMessage = '';
    if (!$('.radio-section').find('input[type="radio"]:checked').length > 0 ) {
        errorMessage = 'one option must be chosen';
    }

    return errorMessage;
};

function validateIdentical(passwordField, params) {
	let errorMessage = '';

	if ($(passwordField).val() !== $(params.linkField).val()) {
		errorMessage = 'The password confirmation does not match';
	}

	return errorMessage;
}

// show errors;
function validateField(obj, callback, params) {
    var errField = document.getElementById(obj.id + '-message');
    var validationParams = {};

    if (!$.isEmptyObject(params)) {
        validationParams = params;
    }

    if (typeof(callback) === 'function' && callback(obj, validationParams).length > 0 ) {

        $(errField).hide().html(callback(obj, validationParams)).fadeIn(1000);
        obj.style.borderColor = '#e84042';
        obj.style.transition = 'border-color .2s';

        return false;
    }

    return true;
}

var getErrorValidateQuantityPeople =  function(params) {
    var quantityAdults = parseInt($('#f_quantity1').val(), 10);
    var quantityChild = parseInt($('#f_quantity2').val(), 10);

    if (quantityAdults < 1 && quantityChild < 1) {
        return 'People field is required';
    }

    var minValue = getMinTotlGuests();
    var maxValue = getMaxTotlGuests();

    var errorMessage = '';

    if (!$.isEmptyObject(params)) {
        if ('min' in params) {
            minValue = parseInt(params.min, 10);
        }

        if ('max' in params) {
            maxValue = parseInt(params.max, 10);
        }
    }

    if (quantityAdults < minValue) {
        errorMessage = 'At least ' + minValue + ' must be an adult';
    }

    if (quantityAdults + quantityChild > maxValue) {
        errorMessage = 'Maximum amount of people is ' + maxValue;
    }

    return errorMessage;
};

function checkQuantityPeople(callback, params) {
    var errorField = $('#people-error');
    var validationParams = {};

    if (!$.isEmptyObject(params)) {
        validationParams = params;
    }

    if (typeof(callback) === 'function' && callback(validationParams).length > 0 ) {

        $(errorField).hide().html(callback(validationParams)).fadeIn(1500);

        return false;
    }

    $(errorField).hide().html('').fadeIn(1500);

    return true;
}

// validation Number;
const getNumberValidationErrorMessage = function(obj) {
    var errorMessage = '';

    const regExForNumber = /^[+]?[0-9]+$/;

    if ((!regExForNumber.test(obj.value))) {
        errorMessage = 'Please enter only numbers';
    }
    return errorMessage;
};


// validation selected option;
const getSelectedOptionFieldValidationErrorMessage = function(obj) {
    var errorMessage = '';

    let elementName = $(obj).attr('data-fieldname');
    if (typeof elementName === 'undefined') {
        elementName = 'Field';
    }

    if (!obj.value) {
        errorMessage = 'Please select proper ' +  elementName;
    }

    return errorMessage;
};

// validation empty Field;
const getEmptyFieldValidationErrorMessage = function(obj) {
    var errorMessage = '';

    let elementName = $(obj).attr('data-fieldname');
    if (typeof elementName === 'undefined') {
        elementName = 'Field';
    }

    if (!obj.value) {
        errorMessage = 'This ' +  elementName  +  ' must be filled ';
    }

    return errorMessage;
};

// validation radio checked;
const validateFieldRadioChecked = function(obj) {
    var errorMessage = '';

    if (!$(obj).find('input[type="radio"]:checked').length > 0) {
        errorMessage = 'One option must be chosen';
    }

    return errorMessage;
};

// validation checkbox checked;
const validateFieldCheckboxChecked = function(obj) {
    var errorMessage = '';

    if (!$(obj).find('input[type="checkbox"]:checked').length > 0) {
        errorMessage = 'One option must be chosen';
    }

    return errorMessage;
};

//input type date
function validateDateOfBirth(obj) {
    let errorMessage = '';
    const inputDate = new Date($(obj).val());
    const today = new Date();
    const minAgeDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    if (inputDate > minAgeDate) {
        errorMessage = "Creator must be over 18 years old";
    }
    return errorMessage;
}

// custom date of birth (3 dropdowns);
const getDateOfBirthdayValidationErrorMessage = function() {
    let errorMessage = '';
    let selectedDate = getSelectedDate();
    let maxdate = new Date();
    maxdate.setFullYear(maxdate.getFullYear() - 18);

    if (selectedDate) {
        if (new Date(Date.UTC(maxdate.getFullYear(), maxdate.getMonth(), maxdate.getDate())) < new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()))) {
            errorMessage = 'Creator must be over 18 years old';
            $('#year_dob').css('border', '1px solid #e84042');
            $('#month_dob').css('border', '1px solid #e84042');
            $('#date_dob').css('border', '1px solid #e84042');
        }
    }
    return errorMessage;
};
const clearErrorDateOfBirthFields = function () {
    $('#year_dob-message').text('');
    $('#month_dob-message').text('');
    $('#date_dob-message').text('');
    $('#year_dob').css('border', '1px solid #CDCFE1');
    $('#month_dob').css('border', '1px solid #CDCFE1');
    $('#date_dob').css('border', '1px solid #CDCFE1');
};

const getSelectDobRequiredValidationErrorMessage = function () {
    let errorMessage = '';
    const month = parseInt($('#month_dob').val(), 10);
    const date = parseInt($('#date_dob option:selected').text(), 10);
    const year = $('#year_dob').val();

    if (!date || !month || !year) {
        errorMessage = 'Select date (date, month, year) of birth please';
        $('#year_dob-message').text('');
        $('#month_dob-message').text('');
    }
    return errorMessage;
};

const getSelectedDate = function () {
    let selectedDate;

    const month = parseInt($('#month_dob').val(), 10);
    const date = parseInt($('#date_dob option:selected').text(), 10);
    const year = $('#year_dob').val();

    clearErrorDateOfBirthFields();

    if (date && month && year) {
        const dateString = `${month}-${date}-${year}`;
        const getDays = (y, m) => new Date(y, m, 0).getDate();

        const days = getDays(year, month);

        if (days < date) {
            $('#date_dob-message').text('Please choose correct day of month');
            $('#month_dob').css({'border': '1px solid #e84042', 'transition': 'border-color .2s'});
            $('#date_dob').css({'border': '1px solid #e84042', 'transition': 'border-color .2s'});
            return false;
        }

        selectedDate = new Date(dateString);
        return selectedDate;
    }
};

function validateCaptcha() {
    if (!$('#g-recaptcha-response').val()) {
        $('#g-recaptcha-response-message').text('Captcha is required');
        return false;
    }
    return true;
}

function onRecaptchaChange() {
    $('#g-recaptcha-response-message').text('');
}

const getIsTcPolicesValidationErrorMessage = function () {
    let errorMessage = '';
    if (!$('#is_tc_accepted').prop('checked')) {
        errorMessage = 'Please tick to confirm T&C policies';
    }

    return errorMessage;
};

const getPasswordValidationLevelFour = function (obj) {
    const passwordRegex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()\\-=_+[\\]{};':"\\\\|,.<>/?]).{8,16}$`);
    let errorMessage = '';

    if (!passwordRegex.test($(obj).val())) {
        errorMessage = 'Password must contains 8-16 characters, at least one uppercase letter, one lowercase letter, one number and any special character.';
    }

    return errorMessage;
};

function onBlurValidateForCondition (elemId, callback, params, allowEmpty = false) {
    $('#' + elemId).on('blur', function () {
        if (!$(this).val().length && allowEmpty) {return false;}

        validateField(document.getElementById(elemId), callback, params);
    });
}
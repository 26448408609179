'use strict';

const CityHostSlider = function() {
    this.selector = '.city-host-slider';
    let hostUrl = '/api/hosts';
    //let translationService; temporary disabled
    let self = this;

    this.init = function () {
        //translationService = new SelectorInitiator().getObjectBySelector('.translatable'); temporary disabled
        $(window).one('scroll', function () {
            requestHosts(hostUrl + '?location=' + $(self.selector).data('location') + '&limit=9999');
        });
    };

    /**
     * @param url (string);
     *
     * @return void;
     */
    const requestHosts = function(url) {
        $.ajax({
            url: url,
            type: 'GET',
            success: function (response) {
                if (response !== null) {
                    // must be minimum 1 color;
                    const colors = ['basic-green', 'basic-yellow', 'basic-red'];
                    let colorKey = 0;

                    response.map(host => {
                        host.color = colors[(colorKey % colors.length)];
                        let $hostItem = createHostCard(host);
                        $(self.selector).append($hostItem);
                        //translationService.addItem($hostItem); temporary disabled
                        colorKey++;
                    });

                    //translationService.initAddedItems(); temporary disabled
                    sliderInitSettings(self.selector);
                }

                else {
                    $(self.selector).parent().remove();
                }
            },

            error: function (response) {
                console.error(response);
                $(self.selector).parent().remove();
            }
        });
    };

    /**
     * @param host {object};
     *
     * @return {jQuery}
     */
    const createHostCard = function(host) {
        const hostCard = $(`<div class="host-card">
                                <div class="article card-tile article--primary">
                                    <div class="article__image-container">
                                        <img src="${host.widgetImage}" alt="${host.first_name}">
                                    </div>
                        
                                    <div class="article__body">
                                        <strong class="card-tile__title ${host.color}">${host.first_name}</strong>
                        
                                        <p class="translatable" data-name="tagline">${host.tagline}</p>
                                        
                                        <ul class="list-stars"></ul>
                                    </div>
                                </div>
                            </div>`);

        if (host.rating) {
            for (let i = 0; i < Math.ceil(host.rating); i++) {
                const star = $(`<li><i class="fa fa-star basic-yellow" aria-hidden="true"></i></li>`);
                hostCard.find('.list-stars').append(star);
            }

            const reviewItem = $(`<li>
                                    <span>(${host.reviewCount})</span>
                                </li>`);

            hostCard.find('.list-stars').append(reviewItem);
        }

        return $(hostCard);
    };

    /**
     * sets settings for the host slider on city page;
     * @param obj {object} (jQuery)
     *
     * @return void;
     */
    const sliderInitSettings = function (obj) {
        $(obj).slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            lazyLoad: 'progressive',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots     : false,
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    };
};

new SelectorInitiator().setObject(new CityHostSlider());
const AutoloadButton = function () {

    this.selector = '.autoload';

    this.init = () => {
        let self = this;
        $(window).scroll(function(){
            let btn = document.querySelector(self.selector);
            if (btn && isElementVisibleOnScreen(btn)) {
                $(btn).trigger('click');
            }
        });
    }
};

new SelectorInitiator().setObject(new AutoloadButton());

const LoadByScroll = function () {

    this.selector = '.scroll-one-load';

    this.init = () => {
        let self = this;
        $(window).scroll(function(){
            let $btn = $(self.selector);
            if (!$btn.data('isloaded')) {
                $btn.data('isloaded', true);
                $btn.trigger('click');
            }
        });
    }
};

new SelectorInitiator().setObject(new LoadByScroll());
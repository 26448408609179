'use strict';

/**
 * @param selectedElement (obj);
 *
 * @return void;
 */
function locationSelectRedirect(selectedElement) {
    window.location.href = $(selectedElement).val();
}

function getMaxTotlGuests() {
    return $('#quantity__people').attr('data-max');
}

function getMinTotlGuests() {
    return $('#quantity__people').attr('data-min');
}